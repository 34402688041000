import pattern from "../images/stump-texture.jpg"

const styles = theme => {
  return {
    loginPage: {
      "& .login-page": {
        backgroundImage: "url(" + pattern + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          display: "block",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "linear-gradient(-90deg, rgba(247, 236, 225, 0) 0%, rgba(247, 236, 225, 0.33) 29.56%, #f7ece1 100%)",
        },
        "& .wrapper": {
          position: "relative",
          zIndex: 1,
          padding: "40px 0",
          alignItems: "center",
          [theme.breakpoints.up("md")]: {
            padding: "50px 0 80px",
          },
          "& .intro": {
            flex: "0 0 100%",
            maxWidth: "100%",
            padding: "0 12px",
            textShadow: "0 0 10px #f7ece1",
            [theme.breakpoints.up("md")]: {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            [theme.breakpoints.up("lg")]: {
              flex: "0 0 24%",
              maxWidth: "24%",
              marginLeft: "6.25%",
              marginRight: "18.75%",
            },
            "& h1": {
              color: theme.palette.primary.navy,
              fontSize: 32,
              lineHeight: 1.2,
              letterSpacing: "-0.024em",
              fontWeight: "bold",
              fontFamily: theme.headerFont,
              marginBottom: 40,
              textAlign: "center",
              marginTop: 0,
              whiteSpace: "nowrap",
              [theme.breakpoints.up("md")]: {
                fontSize: 64,
                textAlign: "left",
              },
            },
            "& p": {
              fontSize: 16,
              letterSpacing: "0.016em",
              lineHeight: 2,
              textAlign: "center",
              color: theme.palette.primary.navy,
              marginBottom: 40,
              marginTop: 0,
              [theme.breakpoints.up("md")]: {
                textAlign: "left",
                marginBottom: 0,
              },
            },
          },
          "& .login-wrap": {
            flex: "0 0 100%",
            padding: "0 12px",
            [theme.breakpoints.up("md")]: {
              flex: "0 0 50%",
            },
            [theme.breakpoints.up("lg")]: {
              flex: "0 0 43.75%",
            },
            "& .login": {
              borderRadius: 8,
              backgroundColor: theme.palette.secondary.cream,
              padding: 32,
              [theme.breakpoints.up("md")]: {
                padding: "40px 80px",
              },
              "& h2": {
                fontFamily: theme.headerFont,
                textAlign: "center",
                fontSize: 24,
                letterSpacing: "0.008em",
                color: theme.palette.primary.purple,
                marginTop: 0,
                marginBototm: 24,
                fontWeight: "bold",
              },
              "& p": {
                fontSize: 16,
                letterSpacing: "0.016em",
                lineHeight: 2,
                color: theme.palette.primary.purple,
                textAlign: "center",
                margin: "0 auto 24px",
              },
              "& form": {
                margin: "0 auto",
                "& label": {
                  display: "block",
                  fontSize: 0,
                  lineHeight: 0,
                  letterSpacing: "0.153em",
                  textTransform: "uppercase",
                  marginBottom: 24,
                  "& input": {
                    backgroundColor: "transparent",
                    display: "block",
                    width: "100%",
                    height: 54,
                    padding: 16,
                    fontSize: 16,
                    letterSpacing: "0.016em",
                    border: "1px solid " + theme.palette.secondary.beige,
                    borderRadius: 4,
                  },
                },
                "& button": {
                  fontSize: 18,
                  fontFamily: theme.bodyFont,
                  display: "table",
                  maxWidth: 1118,
                  padding: "16px 24px",
                  position: "relative",
                  fontWeight: "bold",
                  lineHeight: 1.33,
                  borderRadius: theme.border.radius,
                  color: theme.palette.primary.navy,
                  backgroundColor: theme.palette.primary.green,
                  boxShadow: theme.shadow,
                  border: "none",
                  marginLeft: "auto",
                  "& svg": {
                    marginLeft: 12,
                    display: "inline-block",
                  },
                },
              },
            },
          },
        },
      },
    },
  }
}

export default styles
