import React, { useState } from "react"
import Cookies from "js-cookie"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby-link"

import Layout from "../../components/layout"
import styles from "../../page-styles/login.styles"

import { setCustomerToken } from "../../app/actions"
import {
  authenticate,
  createToken,
  setAuthCookie,
} from "../../services/shopify"
import { isBrowser } from "../../helpers"

import Breadcrumb from "../../components/Breadcrumb"

const mapDispatchToProps = dispatch => ({
  setCustomerToken: details => dispatch(setCustomerToken(details)),
})

const LoginPage = ({ classes, setCustomerToken }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [authStatus, setAuthStatus] = useState(undefined)

  if (Cookies.get("customerToken")) {
    const customerToken = JSON.parse(Cookies.get("customerToken"))

    if (authStatus === undefined && customerToken.token) {
      authenticate({ customerToken: customerToken.token })
        .then(res => {
          const { status, data } = res
          if (status === 200 && data.data.customer) {
            setAuthStatus("success")
            setTimeout(() => navigate("/customer/account"), 3000)
          } else {
            setAuthStatus("error")
          }
        })
        .catch(err => {
          setAuthStatus("error")
        })
    }
  }

  const handleChangeUsername = e => {
    setUsername(e.target.value)
  }

  const handleChangePassword = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    createToken({ email: username, password })
      .then(res => {
        const { status, data } = res
        if (
          status === 200 &&
          data.errors.length &&
          data.errors.includes("credentials")
        ) {
          setAuthStatus("credentials")
        }

        if (status === 200 && data.data.token) {
          setCustomerToken(data.data)
          setAuthCookie(data.data)
          setTimeout(() => navigate("/customer/account"), 3000)
        }
      })
      .catch(err => {
        setAuthStatus("error")
      })
  }

  if (authStatus === "success") {
    return (
      <div className={classes.loginPage}>
        <Breadcrumb
          type="page"
          ancestors={null}
          current={{
            title: "Login",
            slug: "login",
            uri: "/customer/login/",
          }}
        />
        <div className="login-page">
          <div className="wrapper">
            <div className="intro">
              <h1>My Account</h1>
              <p>Login to access details of all your previous orders through this website. If you have any queries, please <a href="/contact-us/">contact us</a> and we will do all we can to help</p>
            </div>
            <div className="login-wrap">
              <div className="login">
                <p>You have been logged successfully, redirecting in 3...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={classes.loginPage}>
        <Breadcrumb
          type="page"
          ancestors={null}
          current={{
            title: "Login",
            slug: "login",
            uri: "/customer/login/",
          }}
        />
        <div className="login-page">
          <div className="wrapper">
            <div className="intro">
              <h1>My Account</h1>
              <p>Login to access details of all your previous orders through this website. If you have any queries, please <a style={{color: '#00313C'}} href="/contact-us/">contact us</a> and we will do all we can to help</p>
            </div>
            <div class="login-wrap">
              <div className="login">
                <h2>Login</h2>
                {authStatus === "credentials" && <p>Incorrect credentials</p>}
                {authStatus === "error" && <p>There was an error</p>}
                <form
                  onSubmit={e => {
                    handleSubmit(e)
                  }}
                >
                  <div className="username-input">
                    <label>
                      Email:
                      <input
                        name="username"
                        placeholder="Enter your email..."
                        type="email"
                        onChange={e => handleChangeUsername(e)}
                        style={{background: 'white'}}
                      ></input>
                    </label>
                  </div>
                  <div className="password-input">
                    <label>
                      Password:
                      <input
                        name="password"
                        placeholder="Enter your password..."
                        type="password"
                        onChange={e => handleChangePassword(e)}
                        style={{background: 'white'}}
                      ></input>
                    </label>
                  </div>
                  <button type="submit">
                    Login{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.76"
                      height="10.692"
                      viewBox="0 0 6.76 10.692"
                    >
                      <path
                        id="Path_28883"
                        data-name="Path 28883"
                        d="M1497.349-439l-3.932,3.932L1489.485-439"
                        transform="translate(440.414 1498.763) rotate(-90)"
                        fill="none"
                        stroke="#00313c"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedLoginPage = connect(null, mapDispatchToProps)(LoginPage)

const Page = () => {
  if (!isBrowser) return null
  const StyledLoginPage = withStyles(styles)(ConnectedLoginPage)

  return (
    <Layout meta={{}} title="Please login">
      <StyledLoginPage />
    </Layout>
  )
}

export default Page
